import React, { useCallback } from "react";

import MuiMenuItem from "@material-ui/core/MenuItem";

const MenuItem = React.forwardRef(({ label, value, onClick }, ref) => {
    const handleClick = useCallback(() => {
        onClick(value);
    }, [onClick, value]);
    
    return (
        <MuiMenuItem ref={ref} onClick={handleClick}>
            {label}
        </MuiMenuItem>
    )
});

export default React.memo(MenuItem);
