import { useEffect, useRef } from "react";

import usePosition from "./usePosition";

function useBoardPositionRef() {
    const position = usePosition();
    const positionRef = useRef(position);
    useEffect(() => {
        positionRef.current = position;
    }, [ position ]);
    return positionRef;
}

export default useBoardPositionRef;
