import React, { useCallback } from "react";
import GoogleLogin from "react-google-login";
import Button from "@material-ui/core/Button";

import GoogleIcon from "./GoogleIcon";

function GoogleLoginButton({ label, onSuccess, onFailure, onClick, disabled, className }) {
    const handleClick = useCallback(googleClick => {
        onClick && onClick();
        googleClick();
    }, [ onClick ]);

    return (
        <GoogleLogin clientId="3981612282-rnfs35tguq7anb0t6o1h0ccgm9scbjp4.apps.googleusercontent.com"
                     render={({ onClick, disabled: loading }) =>
                         <Button color="inherit"
                                 variant="contained"
                                 className={className}
                                 disabled={disabled || loading}
                                 onClick={() => handleClick(onClick)}
                                 startIcon={<GoogleIcon/>}>
                             {label}
                         </Button>
                     }
                     prompt="select_account"
                     onSuccess={onSuccess}
                     onFailure={onFailure}/>
    )
}

export default React.memo(GoogleLoginButton);
