import React, { useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: theme.dnd.zIndex.toolbar
    }
}));

function Toolbar({ children, className, show }) {
    // Otherwise the board is clicked and all tiles unselected
    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    const classes = useStyles();

    if (!show) {
        return null;
    }

    return (
        <Paper className={clsx([ classes.root, className ])} square onClick={handleClick} elevation={0}>
            {children}
        </Paper>
    )
}

export default Toolbar;
