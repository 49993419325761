import React, { useEffect, useRef } from "react";
import { useDragLayer } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";

import { readPieceFromDragItem, readRotationFromDragItem, readTileFromDragItem } from "./dragItemUtil";
import DragPreview from "./DragPreview";
import SourceTypes from "./constant/sourceTypes";
import { calcPoolX, calcPoolY, calcTileX, calcTileY } from "./util";
import useScaleRef from "./hook/useScaleRef";
import useBoardPositionRef from "./hook/useBoardPositionRef";
import usePositionRef from "./hook/usePositionRef";
import useWait from "./hook/useWait";

const useStyles = makeStyles({
    root: {
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%"
    }
});

function CustomDragLayer() {
    // We need to use refs to prevent initial flicker after e.g. resizing,
    // since the collecting function of useDragLayer doesn't honor its dependencies.
    const scaleRef = useScaleRef();
    const positionRef = usePositionRef();
    const boardPositionRef = useBoardPositionRef();

    const collectedRef = useRef(null);

    const [ wait, done ] = useWait();
    const doneRef = useRef(done);
    useEffect(() => { doneRef.current = done }, [ done ])

    collectedRef.current = useDragLayer(monitor => {
        // This is called a shit tone so debounce most of them
        // We need to use a ref since useDragLayer doesn't honour dependencies
        if (!doneRef.current) {
            return collectedRef.current;
        }
        wait(10);
        const scale = scaleRef.current;
        const position = positionRef.current;
        const boardPosition = boardPositionRef.current;
        const item = monitor.getItem();
        const sourceClientOffset = monitor.getSourceClientOffset();
        const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();
        if (!item || !sourceClientOffset || !differenceFromInitialOffset) {
            return null;
        }

        const piece = readPieceFromDragItem(item);
        const rotation = readRotationFromDragItem(item);
        const shape = piece.shape;
        switch (item.sourceType) {
            case SourceTypes.POOL: {
                const x = calcPoolX({ sourceClientOffset, scale, shape, position });
                const y = calcPoolY({ sourceClientOffset, scale, shape, position });
                return { x, y, piece, rotation };
            }
            case SourceTypes.TILE: {
                differenceFromInitialOffset.x += boardPosition.x;
                differenceFromInitialOffset.y += boardPosition.y;
                const { x: initialX, y: initialY } = readTileFromDragItem(item);
                const x = calcTileX({ initialX, differenceFromInitialOffset, scale });
                const y = calcTileY({ initialY, differenceFromInitialOffset, scale });
                return { x, y, piece, rotation };
            }
            default: {
                throw new Error("Illegal sourceType '" + item.sourceType + "'")
            }
        }
    });

    const collected = collectedRef.current;

    const classes = useStyles();

    if (!collected) {
        return null;
    }

    const { x, y, piece, rotation } = collected;

    return (
        <div className={classes.root}>
            <DragPreview rotation={rotation} x={x} y={y} piece={piece}/>
        </div>
    )
}

export default CustomDragLayer;
