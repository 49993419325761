import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Pattern from "./Pattern";
import useScale from "./hook/useScale";
import usePosition from "./hook/usePosition";
import { calcPatternWrapperStyle } from "./util";

const useStyles = makeStyles({
    root: {
        display: "inline-block",
        position: "absolute"
    }
});

function DragPreview({ x, y, piece, rotation }) {
    const classes = useStyles();
    const { shape } = piece;
    const scale = useScale();
    const position = usePosition();
    const style = calcPatternWrapperStyle({ x, y, position, shape, scale, rotation });
    return (
        <div className={classes.root} style={style}>
            <Pattern piece={piece} scale={scale} id="dragpreview"/>
        </div>
    )
}

export default DragPreview;
