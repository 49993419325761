import SourceTypes from "./constant/sourceTypes";
import Constants from "./constant/constants";

export function createPoolDragItem({ piece }) {
    return {
        type: Constants.ITEM_TYPE,
        sourceType: SourceTypes.POOL,
        payload: { piece }
    }
}

export function createTileDragItem({ tile }) {
    return {
        type: Constants.ITEM_TYPE,
        sourceType: SourceTypes.TILE,
        payload: { tile }
    }
}

export function readPieceFromDragItem(item) {
    const { sourceType, payload } = item;
    switch (sourceType) {
        case SourceTypes.POOL: {
            return payload.piece;
        }
        case SourceTypes.TILE: {
            return payload.tile.piece;
        }
        default: {
            throw new Error("Illegal sourceType '" + sourceType + "'");
        }
    }
}

export function readRotationFromDragItem(item) {
    const { sourceType, payload } = item;
    switch (sourceType) {
        case SourceTypes.POOL: {
            return 0;
        }
        case SourceTypes.TILE: {
            return payload.tile.rotation;
        }
        default: {
            throw new Error("Illegal sourceType '" + sourceType + "'");
        }
    }
}

export function readTileFromDragItem(item) {
    const { sourceType, payload } = item;
    switch (sourceType) {
        case SourceTypes.TILE: {
            return payload.tile;
        }
        default: {
            throw new Error("Illegal sourceType '" + sourceType + "'");
        }
    }
}
