import { useEffect, useRef } from "react";

import useBoardPosition from "./useBoardPosition";

function useBoardPositionRef() {
    const boardPosition = useBoardPosition();
    const boardPositionRef = useRef(boardPosition);
    useEffect(() => {
        boardPositionRef.current = boardPosition;
    }, [ boardPosition ]);
    return boardPositionRef;
}

export default useBoardPositionRef;
