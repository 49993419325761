import jwt_decode from "jwt-decode";

export function getJwt() {
    return localStorage.getItem("jwt") || null;
}

export function setJwt(jwt) {
    localStorage.setItem("jwt", jwt);
}

export function removeJwt() {
    localStorage.removeItem("jwt");
}

export function isLoggedIn() {
    return getJwt() != null;
}

export function getUserId() {
    return jwt_decode(getJwt()).user_id;
}

export function getUsername() {
    return jwt_decode(getJwt()).username;
}
