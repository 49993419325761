import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: ({ shape, scale }) => ({
        width: shape.width * scale,
        height: shape.height * scale,
        display: "inline-block"
    })
});

function Pattern({ piece, scale, id }) {
    const { material, shape } = piece;
    const { width, height, d } = shape;
    const classes = useStyles({ shape, scale });

    return (
        <svg className={classes.root} viewBox={`0 0 ${width} ${height}`}>
            <defs>
                <pattern id={id}
                         patternUnits="userSpaceOnUse"
                         width={material.width}
                         height={material.height}
                         viewBox={`0 0 ${material.width} ${material.height}`}>
                    <image href={process.env.PUBLIC_URL + "/" + material.textureUrl}
                           x="0"
                           y="0"
                           width={material.width}
                           height={material.height}
                           imageRendering="pixelated"/>
                </pattern>
            </defs>
            <path d={d}
                  fill={`url(#${id})`}
                  stroke="#000"
                  strokeWidth={1 / scale} />
        </svg>
    )
}

export default Pattern;
