import { useCallback, useRef, useState } from "react";

function useWait() {
    const [ done, setDone ] = useState(true);
    const timeout = useRef();
    const wait = useCallback(millis => {
        clearTimeout(timeout.current);
        setDone(false);
        timeout.current = setTimeout(() => setDone(true), millis);
    }, []);

    return [ wait, done ];
}

export default useWait;
