import { useState, useEffect, useCallback, useContext, useRef } from "react";

import UnauthorizeContext from "../context/UnauthorizeContext";
import ErrorContext from "../context/ErrorContext";
import useMountedRef from "./useMountedRef";

const UNAUTHORIZED_ERROR = {};

function useApi({ sendOnMount, action, handleResponse, skipAuthCheck }) {
    const unauthorize = useContext(UnauthorizeContext);
    const showError = useContext(ErrorContext);

    const mounted = useMountedRef();

    const [ loading, setLoading ] = useState(!!sendOnMount);

    const savedHandleResponse = useRef();
    const savedAction = useRef();
    useEffect(() => {
        savedHandleResponse.current = handleResponse;
        savedAction.current = action;
    });

    const send = useCallback(data => {
        async function run() {
            try {
                if (mounted.current) {
                    setLoading(true);
                    const response = await savedAction.current(data)
                    if (!skipAuthCheck && response.status === 401) {
                        showError(false);
                        unauthorize();
                        throw UNAUTHORIZED_ERROR;
                    } else {
                        await savedHandleResponse.current(response);
                    }
                    if (mounted.current) {
                        setLoading(false);
                    }
                }
            } catch (e) {
                if (e !== UNAUTHORIZED_ERROR) {
                    console.error(e);
                    showError(true);
                }
            }
        }
        run();
    }, [ mounted, showError, skipAuthCheck, unauthorize ]);

    const hasSentOnMount = useRef(false);
    useEffect(() => {
        if (!hasSentOnMount.current && sendOnMount) {
            hasSentOnMount.current = true;
            send();
        }
    }, [send, sendOnMount]);

    return [ send, loading ];
}

export default useApi;
