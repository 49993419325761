import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import clsx from "clsx";

import { createTileDragItem } from "./dragItemUtil";
import Pattern from "./Pattern";
import useScale from "./hook/useScale";
import usePosition from "./hook/usePosition";
import { calcPatternWrapperStyle } from "./util";

const useStyles = makeStyles(theme => ({
    patternWrapper: ({ tile, isDragging }) => ({
        display: "inline-block",
        opacity: isDragging ? 0.5 : 1,
        zIndex: tile.level,
    }),
    outline: ({ tile, isDragging }) => ({
        display: isDragging ? "none" : "inline-block",
        outline: tile.selected ? "1px solid red" : null,
        zIndex: theme.dnd.zIndex.tileOutline,
        pointerEvents: "none",
    }),
    shared: ({ tile, scale, position }) => {
        const { x, y, rotation, piece } = tile;
        const { shape } = piece;
        return calcPatternWrapperStyle({ x, y, position, shape, scale, rotation });
    }
}));

function Tile({ tile, onClick }) {
    const item = createTileDragItem({ tile });
    const [ { isDragging }, ref, preview ] = useDrag({
        item,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    useEffect(() => {
        preview(getEmptyImage())
    }, [ preview ]);

    const scale = useScale();
    const position = usePosition();
    const classes = useStyles({ tile, isDragging, scale, position });

    const handleClick = useCallback(e => {
        e.stopPropagation();
        onClick(tile.id);
    }, [ onClick, tile.id ]);

    return (
        <>
            <div className={clsx([ classes.shared, classes.patternWrapper])} ref={ref} onClick={handleClick}>
                <Pattern piece={tile.piece} scale={scale} id={"tile_" + tile.id}/>
            </div>
            <div className={clsx([ classes.shared, classes.outline])}/>
        </>
    )
}

export default React.memo(Tile);
