import { useEffect, useRef } from "react";

import useScale from "./useScale";

function useScaleRef() {
    const scale = useScale();
    const scaleRef = useRef(scale);
    useEffect(() => {
        scaleRef.current = scale;
    }, [ scale ]);
    return scaleRef;
}

export default useScaleRef;
