import React, { useCallback, useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import { ChevronDown } from "mdi-material-ui";
import clsx from "clsx";

import Pool from "./Pool";

const useStyles = makeStyles(theme => ({
    root: {
        borderBottom: `1px solid ${grey[300]}`
    },
    title: {
        ...theme.typography.overline,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        padding: 10
    },
    collapse: {
        margin: "0 10px 10px 10px",
        display: "flex",
        flexWrap: "wrap"
    },
    pool: {
        margin: 5
    },
    expand: {
        color: theme.palette.action.active,
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.standard,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)"
    }
}));

function Category({ category }) {
    const [ expanded, setExpanded ] = useState(true);
    const { title, pieces } = category;
    const classes = useStyles();
    const handleTitleClick = useCallback(() => {
        setExpanded(prev => !prev);
    }, []);
    return (
        <div className={classes.root}>
            <div onClick={handleTitleClick} className={classes.title}>
                <div>{title}</div>
                <ChevronDown className={clsx([ classes.expand, { [classes.expandOpen]: expanded } ])}/>
            </div>
            <Collapse in={expanded} unmountOnExit mountOnEnter>
                <div className={classes.collapse}>
                    {pieces.map(piece => <Pool className={classes.pool} key={piece.id} piece={piece}/>)}
                </div>
            </Collapse>
        </div>
    )
}

export default React.memo(Category);
