import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Constants from "./constant/constants";
import ToggleIconButton from "./ToggleIconButton";

const useStyles = makeStyles({
    root: {
        border: `1px solid rgba(0, 0, 0, 0.35)`
    },
    button: {
        width: Constants.TILE_SIZE - 2,
        height: Constants.TILE_SIZE - 2,
    },
})

function ToolbarToggleButton({ onChange, Icon, tooltip, selected }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ToggleIconButton className={classes.button} onChange={onChange} selected={selected} Icon={Icon} tooltip={tooltip}/>
        </div>
    )
}

export default React.memo(ToolbarToggleButton);
