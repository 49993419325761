import React from "react";

function Title({ className }) {
    return (
        <svg className={className} viewBox="0 0 111.36 13.746">
            <g transform="translate(282.46 36.444)">
                <g transform="translate(-177.47 -228.21)" strokeWidth=".32358" aria-label="Dungeon Mapper">
                    <path d="m-104.71 200.04q0.0695-0.30967 0.10744-1.1123 0.14536-2.1993 0.54351-4.6009 0.10112-0.50559 0.10112-0.77102 0-0.11376-0.10112-0.13904-0.66991-0.17064-0.66991-0.58775 0-0.30335 0.27176-0.56879 0.27807-0.27175 0.58775-0.27175l0.85318 0.0253q0.58143 0 1.5989-0.0822 0.86583-0.0758 1.4093-0.0758 1.7632 0 2.8503 0.99222 1.0933 0.9859 1.0933 2.6164 0 1.8201-1.6811 3.2926-1.6811 1.4662-4.07 2.0034-0.63198 0.13904-0.8911 0.24016-0.25911 0.10112-0.60671 0.32231-0.42975 0.29704-0.84686 0.29704-0.32231 0-0.57511-0.30968-0.25279-0.30967-0.25279-0.50559 0-0.13271 0.13272-0.38551zm1.6495-0.31599q0.33495 0 1.9149-0.48663 1.5863-0.49295 2.5722-1.5104t0.9859-2.3194q0-1.0428-0.7331-1.6558-0.72678-0.61935-1.9086-0.61935-0.80894 0-1.9465 0.15168-0.14536 0.019-0.23383 0.0253-0.68887 4.2217-0.68887 5.9596 0 0.32231 0.0379 0.45503z"/>
                    <path d="m-90.5 198.75q-0.60671 1.106-1.4093 1.7569-0.7963 0.65095-1.6179 0.65095-0.48663 0-0.9859-0.51191-0.49295-0.51191-0.49295-1.5736 0-0.75206 0.37919-2.2688 0.32863-1.2513 0.32863-1.9023 0.2528-0.53087 0.78998-0.53087 0.36655 0 0.58143 0.24647 0.2212 0.24016 0.2212 0.48663 0 0.44871-0.31599 1.2766-0.18328 0.49295-0.39815 1.3967-0.20856 0.89742-0.20856 1.1502 0 0.84055 0.34759 0.84055 0.54983 0 1.4852-1.0554 0.93534-1.0554 1.2197-2.1614 0.28439-1.0807 0.50559-1.4156 0.2212-0.33495 0.62567-0.33495 0.65727 0 0.65727 0.7331 0 0.10744-0.10744 0.42975-0.37287 1.1186-0.37287 2.5216 0 1.6432 0.55615 1.9655 0.18328 0.10744 0.18328 0.26544 0 0.22751-0.32231 0.42975-0.32231 0.20223-0.61303 0.20223-0.49927 0-0.77102-0.7015-0.26543-0.70783-0.26543-1.896z"/>
                    <path d="m-81.779 200.14q-0.30335-0.56879-0.44239-2.1235-0.13904-1.6179-0.61935-1.6179-0.45503 0-1.5736 1.6874-1.1186 1.6811-1.3777 2.4837-0.05688 0.24015-0.21488 0.39815-0.15168 0.158-0.30335 0.158-0.96062 0-0.96062-0.87846 0-0.29704 0.1896-1.3525 0.24648-1.3651 0.24648-3.3369 0-0.29703 0.25911-0.63198 0.25912-0.34128 0.54983-0.34128 0.36655 0 0.56247 0.18328t0.19592 0.97326q0 0.60039-0.36023 2.3257 1.3714-3.2105 3.0588-3.2105 1.4662 0 1.719 2.3068 0.25279 2.351 0.58143 2.351 0.14536 0 0.33495-0.0632 0.22752-0.0758 0.36023-0.0758 0.30335 0 0.30335 0.35392 0 0.32231-0.41711 0.68886-0.41711 0.36023-1.0491 0.36023-0.7331 0-1.0491-0.6383z"/>
                    <path d="m-74.189 199.13q-0.7015 0.78998-1.6368 1.3019-0.92902 0.50559-1.6052 0.50559-0.66991 0-1.0428-0.39183-0.36655-0.39183-0.36655-1.0807 0-0.97958 0.73942-2.1804 0.74574-1.2008 2.0603-2.0856 1.3209-0.8911 2.3763-0.8911 0.58143 0 1.0049 0.31599 0.10112 0.0758 0.17696 0.1264 0.15168 0.10744 0.27807 0.39815 0.13272 0.2844 0.13272 0.46135 0 0.11376-0.24016 0.76471-0.24648 0.67622-0.34759 1.3145-0.0948 0.63199-0.38551 3.1662-0.25912 2.2056-1.2324 3.2231-0.97326 1.0175-2.1614 1.0175-1.245 0-2.4205-0.84054-1.1692-0.83422-1.1692-1.3777 0-0.48031 0.46767-0.48031 0.18328 0 0.74574 0.36655 1.4409 0.94798 2.4711 0.94798 0.78366 0 1.3145-0.9859 0.53087-0.97958 0.84054-3.596zm0.15168-3.5138q-0.46767 0-1.2261 0.56247-0.75838 0.55615-1.403 1.5041-0.64463 0.94798-0.64463 1.5736 0 0.32863 0.24016 0.32863 0.81526 0 1.6179-0.65727 0.80262-0.66358 1.2956-1.5231 0.49927-0.8595 0.49927-1.3019 0-0.22119-0.11376-0.35391-0.11376-0.13272-0.26543-0.13272z"/>
                    <path d="m-65.455 198.89q0 0.65727-1.106 1.5736-1.106 0.91006-2.4963 0.91006-1.0681 0-1.6811-0.6699-0.61303-0.67623-0.61303-1.877 0-1.7696 1.2513-3.3179 1.2513-1.5484 2.6733-1.5484 0.70783 0 1.1376 0.44871t0.42975 1.0681q0 1.0996-1.0554 2.0982-1.0554 0.99222-2.7049 1.3714-0.1896 0.0442-0.1896 0.22752 0 0.30335 0.26544 0.58143 0.27175 0.27175 0.72046 0.27175 1.2703 0 2.5216-1.3082 0.24016-0.2528 0.46767-0.2528 0.37919 0 0.37919 0.42343zm-4.2406-1.1186q0.99854-0.20224 1.7569-0.85319 0.7647-0.65094 0.7647-1.1818 0-0.20856-0.12008-0.34759-0.11376-0.14536-0.29703-0.14536-0.54983 0-1.2197 0.80894-0.66991 0.80895-0.88478 1.719z"/>
                    <path d="m-58.439 197.86q-0.42343 1.4788-1.6116 2.4963-1.1818 1.0112-2.3636 1.0112-0.93534 0-1.542-0.68254-0.60671-0.68887-0.60671-1.6874 0-1.6305 1.1123-3.2105t2.6986-1.58q1.0807 0 1.7506 0.80262 0.66991 0.79631 0.66991 1.9023 0 0.48031-0.10744 0.94166zm-3.2863-1.9971q-0.44239 0.39183-0.86582 1.2513t-0.42343 1.7253q0 0.52455 0.1896 0.86583 0.19592 0.34127 0.51191 0.34127 0.78366 0 1.6305-0.92902t0.84686-2.0856q0-0.65094-0.27807-1.0681-0.27175-0.42343-0.7647-0.42343-0.46767 0-0.84054 0.32232z"/>
                    <path d="m-51.797 200.14q-0.30335-0.56879-0.44239-2.1235-0.13904-1.6179-0.61935-1.6179-0.45503 0-1.5736 1.6874-1.1186 1.6811-1.3777 2.4837-0.05688 0.24015-0.21488 0.39815-0.15168 0.158-0.30335 0.158-0.96062 0-0.96062-0.87846 0-0.29704 0.1896-1.3525 0.24648-1.3651 0.24648-3.3369 0-0.29703 0.25911-0.63198 0.25912-0.34128 0.54983-0.34128 0.36655 0 0.56247 0.18328t0.19592 0.97326q0 0.60039-0.36023 2.3257 1.3714-3.2105 3.0588-3.2105 1.4662 0 1.719 2.3068 0.25279 2.351 0.58143 2.351 0.14536 0 0.33495-0.0632 0.22752-0.0758 0.36023-0.0758 0.30335 0 0.30335 0.35392 0 0.32231-0.41711 0.68886-0.41711 0.36023-1.0491 0.36023-0.7331 0-1.0491-0.6383z"/>
                    <path d="m-34.266 194.1-2.1045 2.8439q-1.4599 2.0413-2.2499 2.0413-0.36655 0-0.65095-0.41711-0.28439-0.42343-0.58143-1.2829t-0.41711-1.3019q-0.27175-1.0238-0.36655-1.1628l-0.32231 1.6432q-0.15168 0.79631-0.30967 1.4662-0.158 0.66359-0.34759 1.5989-0.18328 0.93534-0.22752 1.4093-0.01264 0.158-0.30335 0.34127-0.28439 0.18328-0.43607 0.18328-0.30335 0-0.56879-0.28439-0.25912-0.27808-0.25912-0.53719 0-0.60671 0.2528-1.542 1.0428-3.8298 1.1881-4.8221 0.15168-0.99222 0.15168-1.6495 0-0.34127 0.29703-0.52454 0.29703-0.18328 0.77734-0.18328 0.25911 0 0.51823 0.34127 0.25911 0.34127 0.34127 0.63199l-0.0063-0.0126 0.87846 2.4584q0.52455 1.4725 0.7015 1.8707 0.68255-0.73943 2.0224-2.5722 1.3398-1.8264 1.8328-2.351 0.49295-0.52455 0.69519-0.52455 0.30967 0 0.68886 0.25912 0.37919 0.25911 0.37919 0.44239 0 0.13903-0.24647 1.9023-0.17064 1.245-0.30967 2.7871t-0.13904 1.8201q0 0.34127 0.08848 0.70151 0.0632 0.27807 0.0632 0.52455 0 0.62566-0.2212 0.85318-0.21488 0.22751-0.66991 0.22751-0.8279 0-0.8279-1.245 0-1.1755 0.21488-2.8692 0.21488-1.7 0.39183-2.6101 0.0632-0.32863 0.08216-0.45503z"/>
                    <path d="m-26.79 198.94q-1.6621 2.193-3.2105 2.193-0.56879 0-0.97958-0.44871-0.41079-0.44871-0.41079-1.1439 0-1.6748 1.4915-3.5644 1.4915-1.896 3.0083-1.896 0.77102 0 1.0112 0.77735 0.72678 0 0.72678 0.55615 0 0.26543-0.13904 0.96062-0.25279 1.3019-0.25279 2.0792 0 0.80895 0.10744 1.1376 0.11376 0.32863 0.16432 0.42975 0.05056 0.10112 0.30335 0.21488 0.25279 0.12007 0.25279 0.33495 0 0.32231-0.32863 0.53719-0.32231 0.21487-0.67623 0.21487-1.1881 0-1.0681-2.3826zm-0.37919-3.5075q-0.63831 0-1.6621 1.4156-1.0175 1.4093-1.0175 2.3952 0 0.51823 0.21488 0.51823 0.74574 0 1.8328-1.3209 1.0933-1.3272 1.0933-2.3194 0-0.31599-0.14536-0.49927-0.13904-0.18959-0.31599-0.18959z"/>
                    <path d="m-22.069 200.61q-0.10744 0.44239-0.21488 1.3398l-0.30335 2.4837q-0.07584 0.60671-0.29703 0.84054-0.21488 0.23384-0.53087 0.23384-0.28439 0-0.54351-0.21488-0.25279-0.20856-0.25279-0.46767 0-0.51191 0.158-1.1818 0.94166-3.7603 1.0301-7.805v-0.1264q0-0.84686 0.04424-1.1249 0.05056-0.28439 0.27175-0.49927 0.22752-0.21487 0.63831-0.21487 0.68886 0 0.68886 1.0554 0 0.42975-0.158 1.4599l-0.0632 0.43607q0.72046-1.2008 1.7127-1.9908 0.99222-0.7963 1.7759-0.7963 0.70782 0 1.2008 0.50558 0.49927 0.50559 0.49927 1.2703 0 2.0413-1.5863 3.7161-1.58 1.6684-3.1283 1.6684-0.7331 0-0.94166-0.58775zm3.7098-5.138q-0.77102 0-2.0034 1.5041-1.2261 1.5041-1.2261 2.3636 0 0.2212 0.21488 0.39816 0.2212 0.17063 0.51191 0.17063 0.80262 0 1.8391-1.2576 1.0428-1.264 1.0428-2.4395 0-0.39183-0.12008-0.56247-0.12008-0.17695-0.25912-0.17695z"/>
                    <path d="m-13.726 200.61q-0.10744 0.44239-0.21488 1.3398l-0.30335 2.4837q-0.07584 0.60671-0.29703 0.84054-0.21488 0.23384-0.53087 0.23384-0.28439 0-0.54351-0.21488-0.2528-0.20856-0.2528-0.46767 0-0.51191 0.158-1.1818 0.94166-3.7603 1.0301-7.805v-0.1264q0-0.84686 0.04424-1.1249 0.05056-0.28439 0.27175-0.49927 0.22752-0.21487 0.63831-0.21487 0.68886 0 0.68886 1.0554 0 0.42975-0.158 1.4599l-0.0632 0.43607q0.72046-1.2008 1.7127-1.9908 0.99222-0.7963 1.7759-0.7963 0.70783 0 1.2008 0.50558 0.49927 0.50559 0.49927 1.2703 0 2.0413-1.5863 3.7161-1.58 1.6684-3.1283 1.6684-0.7331 0-0.94166-0.58775zm3.7098-5.138q-0.77102 0-2.0034 1.5041-1.2261 1.5041-1.2261 2.3636 0 0.2212 0.21488 0.39816 0.2212 0.17063 0.51191 0.17063 0.80262 0 1.8391-1.2576 1.0428-1.264 1.0428-2.4395 0-0.39183-0.12008-0.56247-0.12008-0.17695-0.25911-0.17695z"/>
                    <path d="m-1.1941 198.89q0 0.65727-1.106 1.5736-1.106 0.91006-2.4963 0.91006-1.0681 0-1.6811-0.6699-0.61303-0.67623-0.61303-1.877 0-1.7696 1.2513-3.3179 1.2513-1.5484 2.6733-1.5484 0.70783 0 1.1376 0.44871t0.42975 1.0681q0 1.0996-1.0554 2.0982-1.0554 0.99222-2.7049 1.3714-0.1896 0.0442-0.1896 0.22752 0 0.30335 0.26543 0.58143 0.27175 0.27175 0.72046 0.27175 1.2703 0 2.5216-1.3082 0.24016-0.2528 0.46767-0.2528 0.37919 0 0.37919 0.42343zm-4.2406-1.1186q0.99854-0.20224 1.7569-0.85319 0.7647-0.65094 0.7647-1.1818 0-0.20856-0.12008-0.34759-0.11376-0.14536-0.29703-0.14536-0.54983 0-1.2197 0.80894-0.66991 0.80895-0.88478 1.719z"/>
                    <path d="m1.4286 197.93 0.10744-0.20223q0.7647-1.5357 1.6432-2.49 0.88478-0.96062 1.7253-0.96062 0.64463 0 1.0491 0.35391 0.41079 0.35392 0.41079 0.84055 0 0.30335-0.13904 0.52454-0.13904 0.21488-0.38551 0.21488-0.39183 0-0.71415-0.32231-0.22752-0.20856-0.36023-0.20856-0.47399 0-1.5863 1.4725-1.1123 1.4725-1.3398 3.3116-0.069519 0.53087-0.30967 0.73943-0.24016 0.21487-0.65095 0.21487-0.43607 0-0.61935-0.49295-0.18328-0.49927-0.18328-1.6242 0-0.46136 0.025279-1.245 0.02528-0.73942 0.14536-1.8075 0.031599-0.27808 0.044239-0.43607 0.056879-0.61935 0.1264-0.84686 0.075838-0.23384 0.34127-0.43608 0.26543-0.20223 0.58775-0.20223 0.34127 0 0.51823 0.19591 0.17696 0.19592 0.17696 0.42976 0 0.29703-0.28439 1.2324-0.10112 0.32231-0.17064 0.77102z"/>
                </g>
            </g>
        </svg>
    )
}

export default React.memo(Title);
