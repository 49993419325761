import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import grey from "@material-ui/core/colors/grey";

const theme = createMuiTheme({
    dnd: {
        zIndex: {
            tileOutline: 1100,
            grid: 1000,
            toolbar: 1200,
            palette: 1300
        }
    },
    palette: {
        background: {
            default: grey[200]
        },
        primary: {
            main: "#d90712"
        }
    }
});

console.log(theme);

export default theme;
