import React, { useCallback, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";

import GoogleLoginButton from "../GoogleLoginButton";
import { getUsername, setJwt } from "../storage";
import useApi from "../hook/useApi";
import { postJwt } from "../client";
import useSnackbar from "../hook/useSnackbar";
import Logo from "../Logo";
import Title from "../Title";

const useStyles = makeStyles({
    root: {
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: 10
    },
    logo: {
        width: 160,
        height: 160,
        marginTop: 40
    },
    title: {
        width: "100%",
        height: 32,
        marginTop: 20,
        fill: grey[900]
    },
    button: {
        backgroundColor: "white",
        marginTop: 60
    }
});

function Login({ history }) {
    const [ dialogText, setDialogText ] = useState("");
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const snackbar = useSnackbar();

    const [ createJwt, createJwtLoading ] = useApi({
        action: postJwt,
        handleResponse: async response => {
            if (response.ok) {
                const { jwt } = await response.json();
                setJwt(jwt);
                snackbar(<>Signed in as <b>{getUsername()}</b></>);
                history.push("/builder/0");
            } else {
                setDialogText("Your Google account doesn't have access to this site.");
                setDialogOpen(true);
            }
        }
    })

    const handleGoogleSuccess = useCallback(async ({ tokenId }) => {
        createJwt(tokenId);
    }, [ createJwt ]);

    const handleGoogleFailure = useCallback(e => {
        console.error(e);
        setDialogText("Google authentication failed.");
        setDialogOpen(true);
    }, []);

    const classes = useStyles();

    return (
        <>
            <Dialog open={dialogOpen}
                    maxWidth="xs"
                    onClose={handleCloseDialog}
                    fullWidth>
                <DialogTitle>Login failed</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
            <div className={classes.root}>
                <Logo className={classes.logo}/>
                <Title className={classes.title}/>
                <GoogleLoginButton label="Sign in"
                                   className={classes.button}
                                   disabled={createJwtLoading}
                                   onSuccess={handleGoogleSuccess}
                                   onFailure={handleGoogleFailure}/>
            </div>
        </>
    )
}

export default Login;
