import React, { useCallback } from "react";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { MenuDown, MenuLeft, MenuRight, MenuUp } from "mdi-material-ui";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    rootX: {
        display: "flex",
        alignItems: "center"
    },
    rootY: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    icon: {
        color: theme.palette.action.active
    },
    inactiveIcon: {
        opacity: 0
    }
}));

function PositionSlider({ value, onChange, onRelease, axis, className }) {
    // We flip the y variant since Material UI uses up as positive, but we want down
    const handleChange = useCallback((e, newValue) => {
        onChange(axis === "x" ? newValue : -newValue);
    }, [ onChange, axis ]);

    const classes = useStyles();

    if (axis === "x") {
        return (
            <div className={clsx([ classes.rootX, className ])}>
                <MenuLeft className={clsx([classes.icon, value >= 0 && classes.inactiveIcon])}/>
                <Slider track={false}
                        orientation="horizontal"
                        value={value}
                        onChange={handleChange}
                        min={-1}
                        max={1}
                        step={0.1}
                        onChangeCommitted={onRelease}/>
                <MenuRight className={clsx([classes.icon, value <= 0 && classes.inactiveIcon])}/>
            </div>
        )
    } else if (axis === "y") {
        return (
            <div className={clsx([ classes.rootY, className ])}>
                <MenuUp className={clsx([classes.icon, value >= 0 && classes.inactiveIcon])}/>
                <Slider track={false}
                        orientation="vertical"
                        value={-value}
                        onChange={handleChange}
                        min={-1}
                        max={1}
                        step={0.1}
                        onChangeCommitted={onRelease}/>
                <MenuDown className={clsx([classes.icon, value <= 0 && classes.inactiveIcon])}/>
            </div>
        )
    } else {
        throw new Error("Invalid axis " + axis);
    }
}

export default React.memo(PositionSlider);
