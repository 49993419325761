import { useCallback, useRef } from "react";

function useCounter(initial) {
    const n = useRef(initial);

    const next = useCallback(() => {
        const res = n.current;
        n.current++;
        return res;
    }, []);

    const set = useCallback(value => {
        n.current = value;
    }, []);

    return [ next, set ];
}

export default useCounter;
