import { getJwt } from "./storage";

let BASE_URL;
if (process.env.NODE_ENV === "production") {
    BASE_URL = "https://dungeonmapper.net/api";
} else {
    BASE_URL = `http://localhost:3501/api`;
}

function get({ endpoint }) {

    return fetch(BASE_URL + endpoint, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + getJwt(),
            "Content-Type": "application/json"
        }
    });
}

function put({ endpoint, body }) {
    return fetch(BASE_URL + endpoint, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer " + getJwt(),
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });
}

function post({ endpoint, body }) {
    const headers = { "Content-Type": "application/json" };
    const jwt = getJwt();
    if (jwt) {
        headers["Authorization"] = "Bearer " + jwt;
    }
    return fetch(BASE_URL + endpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(body)
    });
}

function remove({ endpoint}) {
    return fetch(BASE_URL + endpoint, {
        method: "DELETE",
        headers: {
            "Authorization": "Bearer " + getJwt()
        }
    });
}

export const postJwt = tokenId => post({ endpoint: "/jwts", body: { tokenId } })
export const postMap = map => post({ endpoint: "/maps", body: map })
export const getMaterials = () => get({ endpoint: "/materials" });
export const getShapes = () => get({ endpoint: "/shapes" });
export const getPieces = () => get({ endpoint: "/pieces" });
export const getUserMaps = userId => get({ endpoint: `/users/${userId}/maps` });
export const putMap = map => put({ endpoint: `/maps/${map.id}`, body: map });
export const deleteMap = mapId => remove({ endpoint: `/maps/${mapId}`});
