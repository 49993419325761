export async function okJson(response) {
    if (response.ok) {
        return response.json();
    } else {
        return response.json().then(json => Promise.reject(JSON.stringify(json)))
    }
}

export async function ok(response) {
    if (response.ok) {
        return response;
    } else {
        return response.json().then(json => Promise.reject(JSON.stringify(json)))
    }
}
