import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { getEmptyImage } from "react-dnd-html5-backend";

import { createPoolDragItem } from "./dragItemUtil";
import Pattern from "./Pattern";
import Constants from "./constant/constants";

const useStyles = makeStyles({
    root: ({ piece }) => ({
        width: piece.shape.width * Constants.POOL_SCALE,
        height: piece.shape.height * Constants.POOL_SCALE,
        display: "inline-block"
    })
});

function Pool({ piece, className }) {
    const item = createPoolDragItem({ piece });
    const [ , dragRef, preview ] = useDrag({
        item,
        options: {
            dropEffect: "copy"
        },
    });

    useEffect(() => {
        preview(getEmptyImage())
    }, [ preview ]);

    const classes = useStyles({ piece });

    return (
        <div title={piece.name} ref={dragRef} className={clsx([ classes.root, className ])}>
            <Pattern piece={piece} scale={Constants.POOL_SCALE} id={"pool_" + piece.id}/>
        </div>
    )
}

export default Pool;
