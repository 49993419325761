import React, { useCallback } from "react";
import { useDrop } from "react-dnd";

import Constants from "./constant/constants";
import { readPieceFromDragItem, readTileFromDragItem } from "./dragItemUtil";
import SourceTypes from "./constant/sourceTypes";
import useScale from "./hook/useScale";
import usePosition from "./hook/usePosition";
import { calcPoolX, calcPoolY, calcTileX, calcTileY } from "./util";
import useBoardPosition from "./hook/useBoardPosition";

function Board({ onDrop, onBackgroundClick, className, children }) {
    const scale = useScale();
    const position = usePosition();
    const boardPosition = useBoardPosition();

    const [ , dropRef ] = useDrop({
        accept: Constants.ITEM_TYPE,
        drop: (item, monitor) => {
            const piece = readPieceFromDragItem(item);
            const shape = piece.shape;
            switch (item.sourceType) {
                case SourceTypes.POOL: {
                    const sourceClientOffset = monitor.getSourceClientOffset();
                    sourceClientOffset.y -= boardPosition.y;
                    sourceClientOffset.x -= boardPosition.x;
                    onDrop({
                        x: calcPoolX({ sourceClientOffset, scale, shape, position }),
                        y: calcPoolY({ sourceClientOffset, scale, shape, position }),
                        item
                    });
                    break;
                }
                case SourceTypes.TILE: {
                    const { x: initialX, y: initialY } = readTileFromDragItem(item);
                    const differenceFromInitialOffset = monitor.getDifferenceFromInitialOffset();
                    onDrop({
                        x: calcTileX({ initialX, differenceFromInitialOffset, scale }),
                        y: calcTileY({ initialY, differenceFromInitialOffset, scale }),
                        item
                    });
                    break;
                }
                default: {
                    throw new Error("Illegal sourceType '" + item.sourceType + "'")
                }
            }
        }
    });

    const handleClick = useCallback(() => {
        onBackgroundClick();
    }, [ onBackgroundClick ]);

    return (
        <div className={className} ref={dropRef} onClick={handleClick}>
            {children}
        </div>
    )
}

export default Board;
