import React from "react";

function Logo({ className }) {
    return (
        <svg className={className} viewBox="0 0 84.667 84.667">
            <defs>
                <radialGradient id="radialGradient844" cx="45.114" cy="255.45" r="42.333" gradientTransform="matrix(.86603 .5 -.53125 .92015 143.32 -7.1531)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#bb050e" offset="0"/>
                    <stop stopColor="#d90712" offset="1"/>
                </radialGradient>
            </defs>
            <g transform="translate(0 -212.33)">
                <path transform="rotate(-30 57.307 262.01)" d="m81.27 267.37c-12.098 20.954-9.0647 19.203-33.26 19.203s-21.162 1.7511-33.26-19.203c-12.098-20.954-12.098-17.452 0-38.405 12.098-20.954 9.0647-19.203 33.26-19.203s21.162-1.7511 33.26 19.203c12.098 20.954 12.098 17.452 0 38.405z" fill="url(#radialGradient844)"/>
                <g fill="#fff" strokeWidth=".91499" aria-label="DM">
                    <path d="m13.58 263.6q0.19658-0.87567 0.3038-3.1453 0.41103-6.219 1.5369-13.01 0.28593-1.4297 0.28593-2.1802 0-0.32167-0.28593-0.39315-1.8943-0.48252-1.8943-1.662 0-0.8578 0.76844-1.6084 0.78632-0.76844 1.662-0.76844l2.4126 0.0715q1.6441 0 4.5213-0.23232 2.4483-0.21445 3.9852-0.21445 4.986 0 8.0597 2.8057 3.0917 2.7878 3.0917 7.3985 0 5.1468-4.7536 9.3107-4.7536 4.146-11.509 5.6651-1.7871 0.39315-2.5198 0.67909-0.7327 0.28593-1.7156 0.91141-1.2152 0.83993-2.3947 0.83993-0.91141 0-1.6262-0.87567t-0.71483-1.4297q0-0.37529 0.37529-1.0901zm4.6643-0.89354q0.94715 0 5.4149-1.376 4.4856-1.3939 7.2734-4.2711t2.7878-6.5586q0-2.9487-2.073-4.6822-2.0551-1.7513-5.397-1.7513-2.2875 0-5.5042 0.4289-0.41103 0.0536-0.66122 0.0715-1.9479 11.938-1.9479 16.852 0 0.91141 0.10722 1.2867z"/>
                    <path d="m66.889 246.81-5.951 8.0419q-4.1282 5.7723-6.362 5.7723-1.0365 0-1.8407-1.1795-0.80419-1.1974-1.6441-3.6278t-1.1795-3.6814q-0.76845-2.8951-1.0365-3.2882l-0.91141 4.6464q-0.4289 2.2517-0.87567 4.146-0.44677 1.8764-0.9829 4.5213-0.51825 2.6449-0.64335 3.9852-0.03574 0.44677-0.8578 0.96502-0.80419 0.51826-1.2331 0.51826-0.8578 0-1.6084-0.80419-0.7327-0.78632-0.7327-1.519 0-1.7156 0.71483-4.3605 2.9487-10.83 3.3597-13.635 0.4289-2.8057 0.4289-4.6643 0-0.96502 0.83993-1.4833 0.83993-0.51825 2.1981-0.51825 0.7327 0 1.4654 0.96502 0.7327 0.96503 0.96502 1.7871l-0.01787-0.0357 2.484 6.9518q1.4833 4.1639 1.9837 5.2898 1.93-2.0909 5.7187-7.2734 3.7886-5.1647 5.1825-6.648 1.3939-1.4833 1.9658-1.4833 0.87567 0 1.9479 0.7327 1.0722 0.73271 1.0722 1.251 0 0.39316-0.69696 5.3791-0.48251 3.5206-0.87567 7.881-0.39316 4.3605-0.39316 5.1468 0 0.96503 0.25019 1.9837 0.17871 0.78632 0.17871 1.4833 0 1.7692-0.62548 2.4126-0.60761 0.64335-1.8943 0.64335-2.3411 0-2.3411-3.5206 0-3.324 0.60761-8.1134 0.60761-4.8073 1.108-7.3807 0.17871-0.92928 0.23232-1.2867z"/>
                </g>
            </g>
        </svg>
    )
}

export default React.memo(Logo);
