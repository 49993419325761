import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
    ArrowCollapseDown,
    ArrowCollapseUp,
    ArrowDown,
    ArrowUp, DeleteForever, MagnifyMinus, MagnifyPlus,
    RotateLeft,
    RotateRight,
    Grid as GridIcon,
    Magnet,
    ContentSave,
    PlusCircle,
    FolderOpen,
    ContentDuplicate,
    Calculator
} from "mdi-material-ui";
import { makeStyles } from "@material-ui/core/styles";

import Board from "../Board";
import Palette from "../Palette";
import useCounter from "../hook/useCounter";
import SourceTypes from "../constant/sourceTypes";
import { calculate, parseShapeD, parseShapeHeight, parseShapeWidth, snapX, snapY, wrapAround } from "../util";
import CustomDragLayer from "../CustomDragLayer";
import { readPieceFromDragItem, readRotationFromDragItem, readTileFromDragItem } from "../dragItemUtil";
import ScaleContext from "../context/ScaleContext";
import PiecesContext from "../context/PiecesContext";
import BoardPositionContext from "../context/BoardPositionContext";
import Constants from "../constant/constants";
import IconButton from "../IconButton";
import PositionContext from "../context/PositionContext";
import PositionSlider from "../PositionSlider";
import useInterval from "../hook/useInterval";
import TextField from "@material-ui/core/TextField";
import { getUserId } from "../storage";
import useApi from "../hook/useApi";
import { deleteMap, getMaterials, getPieces, getShapes, getUserMaps, postMap, putMap } from "../client";
import { ok, okJson } from "../clientHelper";
import Tile from "../Tile";
import Grid from "../Grid";
import Toolbar from "../Toolbar";
import ToolbarButton from "../ToolbarButton";
import ToolbarToggleButton from "../ToolbarToggleButton";
import useSnackbar from "../hook/useSnackbar";
import IconMenuButton from "../IconMenuButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const EMPTY_ARRAY = [];
const DELETE_KEY_CODE = 46;
const W_KEY_CODE = 87;
const A_KEY_CODE = 65;
const S_KEY_CODE = 83;
const D_KEY_CODE = 68;
const Q_KEY_CODE = 81;
const E_KEY_CODE = 69;
const ESC_KEY_CODE = 27;
const PLUS_KEY_CODE = 107;
const MINUS_KEY_CODE = 109;
const LEFT_KEY_CODE = 37;
const UP_KEY_CODE = 38;
const RIGHT_KEY_CODE = 39;
const DOWN_KEY_CODE = 40;

const useStyles = makeStyles(theme => ({
    toolbarButtonWrapper: {
        border: `1px solid rgba(0, 0, 0, 0.35)`
    },
    toolbarButton: {
        width: Constants.TILE_SIZE - 2,
        height: Constants.TILE_SIZE - 2,
    },
    bottomRight: {
        position: "absolute",
        right: 0,
        bottom: 0,
        display: "flex"
    },
    bottomLeft: {
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "flex"
    },
    topLeft: {
        position: "absolute",
        left: 0,
        top: 0,
        display: "flex"
    },
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    right: {
        position: "fixed",
        right: 0,
        top: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 400,
        paddingBottom: 100 - 28 - 10
    },
    topButtonLeft: {
        flex: 1,
        margin: 10,
        fontSize: 12
    },
    topButtonRight: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    top: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    nameTextFieldInput: {
        textAlign: "center"
    },
    top2: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 10px 10px 10px",
        flexGrow: 1,
        outline: "none"
    },
    top3: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    board: {
        position: "relative",
        backgroundColor: "lightgrey",
        overflow: "hidden",
        width: "100%",
        height: "100%",
    },
    positionSliderX: {
        width: 400,
        height: 28,
        marginRight: 28
    },
    positionSliderY: {
        width: 28,
        height: 400
    },
    controls: {
        position: "absolute",
        bottom: 50,
        left: 500
    }
}));


function normalizeTiles(tiles) {
    return tiles.reduce((res, curr) => {
        const tile = { ...curr };
        tile.piece_id = curr.piece.id;
        delete tile.piece;
        res.push(tile);
        return res;
    }, []);
}

function Builder({ match, history }) {
    const mapId = useMemo(() => parseInt(match.params.mapId), [ match.params.mapId ]);

    const [ tiles, setTiles ] = useState(EMPTY_ARRAY);

    const tileIsSelected = useMemo(() => tiles.some(t => t.selected), [ tiles ]);

    const [ nextLevel, setNextLevel ] = useCounter(1);
    const [ nextTileId ] = useCounter(1);

    const [ shouldSnap, setShouldSnap ] = useState(true);

    const [ position, setPosition ] = useState({ x: 0, y: 0 });

    const handleMovePositionRight = useCallback((nbr = 1) => {
        setPosition(prev => ({ ...prev, x: prev.x + Constants.TILE_SIZE * nbr }));
    }, []);

    const handleMovePositionLeft = useCallback((nbr = 1) => {
        setPosition(prev => ({ ...prev, x: prev.x - Constants.TILE_SIZE * nbr }));
    }, []);

    const handleMovePositionUp = useCallback((nbr = 1) => {
        setPosition(prev => ({ ...prev, y: prev.y - Constants.TILE_SIZE * nbr }));
    }, []);

    const handleMovePositionDown = useCallback((nbr = 1) => {
        setPosition(prev => ({ ...prev, y: prev.y + Constants.TILE_SIZE * nbr }));
    }, []);

    const [ tileSize, setTileSize ] = useState(Constants.TILE_SIZE * Constants.INITIAL_SCALE);
    const scale = tileSize / Constants.TILE_SIZE;

    const handleZoomOut = useCallback(() => {
        setTileSize(prev => prev / 2);
        setPosition(prev => ({
            ...prev,
            x: prev.x - Constants.VISIBLE_BOARD_WIDTH / 2 / scale,
            y: prev.y - Constants.VISIBLE_BOARD_HEIGHT / 2 / scale
        }));
    }, [ scale ]);

    const handleZoomIn = useCallback(() => {
        setTileSize(prev => prev * 2);
        setPosition(prev => ({
            ...prev,
            x: prev.x + Constants.VISIBLE_BOARD_WIDTH / 4 / scale,
            y: prev.y + Constants.VISIBLE_BOARD_HEIGHT / 4 / scale
        }));
    }, [ scale ]);

    const [ materials, setMaterials ] = useState();
    useApi({
        sendOnMount: true,
        action: getMaterials,
        handleResponse: response => okJson(response).then(setMaterials)
    })

    const [ shapes, setShapes ] = useState();
    useApi({
        sendOnMount: true,
        action: getShapes,
        handleResponse: response => okJson(response).then(setShapes)
    })

    const [ pieces, setPieces ] = useState();
    useApi({
        sendOnMount: true,
        action: getPieces,
        handleResponse: response => okJson(response).then(setPieces)
    })

    const combinedPieces = useMemo(() => {
        if (!materials || !shapes || !pieces) {
            return null;
        }
        const skipKeys = [ "shape_id", "material_id" ];
        return pieces.reduce((res, piece) => {
            piece.material = materials.find(material => material.id === piece.material_id);
            piece.shape = shapes.find(shape => shape.id === piece.shape_id);
            piece.shape.d = parseShapeD(piece.shape.d);
            piece.shape.width = parseShapeWidth(piece.shape.width);
            piece.shape.height = parseShapeHeight(piece.shape.height);
            const pieceWithoutSkippedKeys = Object.keys(piece).reduce((res, key) => {
                if (!skipKeys.includes(key)) {
                    res[key] = piece[key];
                }
                return res;
            }, {});
            res[piece.id] = pieceWithoutSkippedKeys;
            return res;
        }, {});
    }, [ materials, shapes, pieces ]);

    const handleShouldSnapChange = useCallback(value => {
        const checked = value;
        setShouldSnap(checked);
        if (checked) {
            setTiles(prev => {
                const tile = prev.find(t => t.selected);
                if (tile) {
                    const { x, y, piece, rotation } = tile;
                    const shape = piece.shape;
                    return [
                        ...prev.filter(t => t.id !== tile.id),
                        {
                            ...tile,
                            x: snapX({ x, shape, rotation }),
                            y: snapY({ y, shape, rotation }),
                        }
                    ]
                } else {
                    return prev;
                }
            });
        }
    }, []);

    const handleDrop = useCallback(({ x, y, item }) => {
        const { sourceType, payload } = item;
        switch (sourceType) {
            case SourceTypes.POOL: {
                const piece = readPieceFromDragItem(item);
                const shape = piece.shape;
                if (shouldSnap) {
                    const rotation = readRotationFromDragItem(item);
                    x = snapX({ x, shape, rotation });
                    y = snapY({ y, shape, rotation });
                }
                setTiles(prev => {
                    return [
                        ...prev.map(t => ({ ...t, selected: false })),
                        { x, y, piece, level: nextLevel(), id: "_" + nextTileId(), selected: true, rotation: 0 }
                    ]
                });
                break;
            }
            case SourceTypes.TILE: {
                const tile = readTileFromDragItem(item);
                const shape = tile.piece.shape;
                if (shouldSnap) {
                    const rotation = readRotationFromDragItem(item);
                    x = snapX({ x, shape, rotation });
                    y = snapY({ y, shape, rotation });
                }
                setTiles(prev => {
                    return [
                        ...prev.filter(t => t.id !== tile.id).map(t => ({ ...t, selected: false })),
                        { ...payload.tile, x, y, selected: true }
                    ]
                });
                break;
            }
            default: {
                throw new Error("Illegal sourceType '" + sourceType + "'");
            }
        }
    }, [nextLevel, nextTileId, shouldSnap]);

    const handleTileClick = useCallback(tileId => {
        setTiles(prev => {
            const tile = prev.find(t => t.id === tileId);
            if (!tile.selected) {
                return [
                    ...prev.filter(t => t.id !== tileId).map(t => ({ ...t, selected: false })),
                    { ...tile, selected: true }
                ]
            } else {
                return prev;
            }
        });
    }, []);

    const handleUnselectTiles = useCallback(() => {
        setTiles(prev => [ ...prev.map(t => ({ ...t, selected: false })) ]);
    }, []);

    const handleRotate = useCallback(rotationDiff => {
        setTiles(prev => {
            const tile = prev.find(t => t.selected);
            const { id, piece } = tile;
            const shape = piece.shape;
            let { x, y, rotation } = tile;
            rotation = wrapAround({ value: rotation + rotationDiff, min: 0, max: 360});

            // This is to prevent tile walking during continuous rotations in the same direction
            const tinyOffset = wrapAround({ value: rotation, min: 0, max: 180}) < 90 ? 1 : -1;

            if (shouldSnap) {
                x = snapX({ x: x + tinyOffset, shape, rotation });
                y = snapY({ y: y + tinyOffset, shape, rotation });
            }
            return [
                ...prev.filter(t => t.id !== id),
                { ...tile, x, y, rotation }
            ]
        });
    }, [shouldSnap]);

    const handleRotateLeft = useCallback(() => {
        handleRotate(-90);
    }, [ handleRotate ]);

    const handleRotateRight = useCallback(() => {
        handleRotate(90);
    }, [ handleRotate ]);

    const handleMove = useCallback(findOtherTile => {
        setTiles(prev => {
            const sortedTiles = prev.sort((t1, t2) => t1.level > t2.level ? 1 : -1);
            const selectedTile = sortedTiles.find(t => t.selected);
            const selectedTileIndex = sortedTiles.findIndex(t => t.id === selectedTile.id);
            const otherTile = findOtherTile({ sortedTiles, selectedTile, selectedTileIndex });
            if (selectedTile && otherTile && selectedTile !== otherTile) {
                return [
                    ...sortedTiles.filter(t => t.id !== selectedTile.id && t.id !== otherTile.id),
                    { ...selectedTile, level: otherTile.level },
                    { ...otherTile, level: selectedTile.level }
                ]
            } else {
                return prev;
            }
        });
    }, []);

    const handleMoveOut = useCallback(() => {
        handleMove(({ sortedTiles, selectedTileIndex }) => sortedTiles[selectedTileIndex + 1]);
    }, [ handleMove ]);

    const handleMoveIn = useCallback(() => {
        handleMove(({ sortedTiles, selectedTileIndex }) => sortedTiles[selectedTileIndex - 1]);
    }, [ handleMove ]);

    const handleMoveToTop = useCallback(() => {
        handleMove(({ sortedTiles }) => sortedTiles[sortedTiles.length - 1]);
    }, [ handleMove ]);

    const handleMoveToBottom = useCallback(() => {
        handleMove(({ sortedTiles }) => sortedTiles[0]);
    }, [ handleMove ]);

    const handleDelete = useCallback(() => {
        setTiles(prev => prev.filter(t => !t.selected));
    }, []);

    const handleDuplicate = useCallback(() => {
        setTiles(prev => {
            const selectedTile = prev.find(t => t.selected);
            return [
                ...prev.filter(t => t.id !== selectedTile.id),
                { ...selectedTile, selected: false },
                { ...selectedTile, level: nextLevel(), id: "_" + nextTileId(), x: selectedTile.x + tileSize, y: selectedTile.y + tileSize, selected: true }
            ];
        });
    }, [ nextLevel, nextTileId, tileSize ]);

    const handleMoveTile = useCallback(({ dx, dy }) => {
        setTiles(prev => {
            const selectedTile = prev.find(t => t.selected);
            return [
                ...prev.filter(t => t.id !== selectedTile.id),
                { ...selectedTile, x: selectedTile.x + tileSize * dx, y: selectedTile.y + tileSize * dy }
            ];
        });
    }, [ tileSize ]);

    const [ visibleGrid, setVisibleGrid ] = useState(true);

    const handleShowGridChange = useCallback(value => {
        setVisibleGrid(value)
    }, []);

    const [ positionSliderX, setPositionSliderX ] = useState(0);

    const handlePositionSliderXChange = useCallback(value => {
        setPositionSliderX(value);
    }, []);

    const handlePositionSliderXRelease = useCallback(() => {
        setPositionSliderX(0);
    }, []);

    useInterval(() => {
        if (positionSliderX > 0) {
            handleMovePositionRight();
        }
    }, 20 / positionSliderX);

    useInterval(() => {
        if (positionSliderX < 0) {
            handleMovePositionLeft();
        }
    }, -20 / positionSliderX);

    const [ positionSliderY, setPositionSliderY ] = useState(0);

    const handlePositionSliderYChange = useCallback(value => {
        setPositionSliderY(value);
    }, []);

    const handlePositionSliderYRelease = useCallback(() => {
        setPositionSliderY(0);
    }, []);

    useInterval(() => {
        if (positionSliderY > 0) {
            handleMovePositionDown();
        }
    }, 20 / positionSliderY);

    useInterval(() => {
        if (positionSliderY < 0) {
            handleMovePositionUp();
        }
    }, -20 / positionSliderY);

    const classes = useStyles();

    const [ boardLeft, setBoardLeft ] = useState(0);
    const [ boardTop, setBoardTop ] = useState(0);
    const boardRef = useRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(() => {
        if (boardRef.current) {
            setBoardLeft(boardRef.current.offsetLeft);
            setBoardTop(boardRef.current.offsetTop);
        }
    });
    const boardPosition = useMemo(() => ({ x: boardLeft, y: boardTop }), [ boardTop, boardLeft ]);
    useEffect(() => {
        function handleResize() {
            setBoardLeft(boardRef.current.offsetLeft);
            setBoardTop(boardRef.current.offsetTop);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize)
    })

    const [ name, setName ] = useState("");
    const handleNameChange = useCallback(e => {
        e.stopPropagation();
        setName(e.target.value);
    }, []);

    const nameTextFieldInputProps = useMemo(() => ({ className: classes.nameTextFieldInput }), [ classes ]);

    const snackbar = useSnackbar();

    const [ maps, setMaps ] = useState(EMPTY_ARRAY);
    const [ , loadingMaps ] = useApi({
        action: () => getUserMaps(getUserId()),
        sendOnMount: true,
        handleResponse: response => okJson(response).then(setMaps)
    })
    const mapMenuItems = useMemo(() => {
        return maps
            .sort((m1, m2) => m1.name.toLowerCase() > m2.name.toLowerCase() ? 1 : -1)
            .map(map => ({ label: map.name, value: map.id }));
    }, [maps]);

    useEffect(() => {
        if (isNaN(mapId)) {
            history.push("/builder/0");
        } else if (combinedPieces && !loadingMaps) {
            if (mapId > 0) {
                const map = maps.find(m => m.id === mapId);
                if (!map) {
                    history.push("/builder/0");
                } else {
                    const tiles = map.tiles.reduce((arr, tile) => {
                        const tileCopy = { ...tile };
                        tileCopy.piece = combinedPieces[tileCopy.piece_id];
                        tileCopy.selected = false;
                        delete tileCopy.piece_id;
                        arr.push(tileCopy);
                        return arr;
                    }, []);
                    setTiles(tiles);
                    setName(map.name);
                    const maxLevel = tiles.reduce((max, tile) => Math.max(max, tile.level), 0);
                    setNextLevel(maxLevel + 1);
                }
            } else {
                setTiles(EMPTY_ARRAY);
                setName("");
                setPosition({ x: 0, y: 0 });
                setNextLevel(1);
            }
        }
    }, [combinedPieces, history, loadingMaps, mapId, maps]);

    const [ createMap, creatingMap ] = useApi({
        action: postMap,
        handleResponse: response => okJson(response).then(json => {
            setMaps(prev => [
                ...prev.filter(m => m.id !== json.id),
                json
            ]);
            history.push("/builder/" + json.id);
            snackbar(<>Map <b>{json.name}</b> created</>);
        })
    })

    const [ updateMap, updatingMap ] = useApi({
        action: putMap,
        handleResponse: response => okJson(response).then(json => {
            setMaps(prev => [
                ...prev.filter(m => m.id !== json.id),
                json
            ]);
            snackbar(<>Map <b>{name}</b> saved</>);
        })
    })
    
    const handleSaveClick = useCallback(() => {
        const normalizedTiles = normalizeTiles(tiles);
        if (mapId === 0) {
            createMap({ name, user_id: getUserId(), tiles: normalizedTiles });
        } else {
            const map = maps.find(m => m.id === mapId);
            updateMap({ ...map, name, tiles: normalizedTiles })
        }
    }, [tiles, mapId, createMap, name, maps, updateMap]);

    const [ showDeleteMapDialog, setShowDeleteMapDialog ] = useState(false);

    const [ removeMap, removingMap ] = useApi({
        action: deleteMap,
        handleResponse: response => ok(response).then(() => {
            setMaps(prev => [
                ...prev.filter(m => m.id !== mapId)
            ]);
            snackbar("Map deleted");
            history.push("/builder/0");
        })
    })

    const handleDeleteMapConfirm = useCallback(() => {
        setShowDeleteMapDialog(false);
        removeMap(mapId);
    }, [mapId, removeMap]);

    const handleDeleteMap = useCallback(() => {
        setShowDeleteMapDialog(true);
    }, []);

    const handleDeleteMapAbort = useCallback(() => {
        setShowDeleteMapDialog(false);
    }, []);

    const handleCopyMap = useCallback(() => {
        const normalizedTiles = normalizeTiles(tiles);
        createMap({ name: name + " (copy)", user_id: getUserId(), tiles: normalizedTiles });
    }, [createMap, name, tiles]);

    const handleCreateNewMap = useCallback(() => {
        history.push("/builder/0");
    }, [history]);

    const handleWheel = useCallback(e => {
        if (e.shiftKey) {
            if (e.deltaY > 0) {
                handleMovePositionRight(Math.floor(e.deltaY / 100));
            } else if (e.deltaY < 0) {
                handleMovePositionLeft(-Math.floor(e.deltaY / 100));
            }
        } else {
            if (e.deltaY > 0) {
                handleMovePositionDown(Math.floor(e.deltaY / 100));
            } else if (e.deltaY < 0) {
                handleMovePositionUp(-Math.floor(e.deltaY / 100));
            }
        }
    }, [handleMovePositionDown, handleMovePositionLeft, handleMovePositionRight, handleMovePositionUp]);

    const handleKeyDown = useCallback(e => {
        if (e.shiftKey) {
            if (e.keyCode === D_KEY_CODE && tileIsSelected) {
                handleDuplicate();
            } else if (e.keyCode === PLUS_KEY_CODE) {
                handleZoomIn();
            } else if (e.keyCode === MINUS_KEY_CODE) {
                handleZoomOut();
            } else if (e.keyCode === UP_KEY_CODE) {
                handleMovePositionUp();
            } else if (e.keyCode === LEFT_KEY_CODE) {
                handleMovePositionLeft();
            } else if (e.keyCode === DOWN_KEY_CODE) {
                handleMovePositionDown();
            } else if (e.keyCode === RIGHT_KEY_CODE) {
                handleMovePositionRight();
            }
        } else if (tileIsSelected) {
            if (e.keyCode === DELETE_KEY_CODE) {
                handleDelete();
            } else if (e.keyCode === W_KEY_CODE || e.keyCode === UP_KEY_CODE) {
                handleMoveTile({ dx: 0, dy: -1 });
            } else if (e.keyCode === A_KEY_CODE || e.keyCode === LEFT_KEY_CODE) {
                handleMoveTile({ dx: -1, dy: 0 });
            } else if (e.keyCode === S_KEY_CODE || e.keyCode === DOWN_KEY_CODE) {
                handleMoveTile({ dx: 0, dy: 1 });
            } else if (e.keyCode === D_KEY_CODE || e.keyCode === RIGHT_KEY_CODE) {
                handleMoveTile({ dx: 1, dy: 0 });
            } else if (e.keyCode === Q_KEY_CODE) {
                handleRotateLeft();
            } else if (e.keyCode === E_KEY_CODE) {
                handleRotateRight();
            } else if (e.keyCode === ESC_KEY_CODE) {
                handleUnselectTiles();
            } else if (e.keyCode === PLUS_KEY_CODE) {
                handleMoveOut();
            } else if (e.keyCode === MINUS_KEY_CODE) {
                handleMoveIn();
            }
        }
    }, [tileIsSelected, handleDuplicate, handleZoomIn, handleZoomOut, handleMovePositionUp, handleMovePositionLeft, handleMovePositionDown, handleMovePositionRight, handleDelete, handleMoveTile, handleRotateLeft, handleRotateRight, handleUnselectTiles, handleMoveOut, handleMoveIn]);

    const handleSelectMap = useCallback(mapId => {
        history.push("/builder/" + mapId);
    }, [history]);

    const sending = creatingMap || updatingMap || loadingMaps || removingMap;

    const handleCalculate = useCallback(() => {
        const res = calculate({ tiles });
        res.filter(e => e.diff < 0).sort((e1, e2) => e1.diff > e2.diff ? 1 : -1).forEach(e => console.log(e.piece.name, e.diff))
    }, [tiles]);

    if (!combinedPieces) {
        return <div>Loading...</div>
    }

    return (
        <PositionContext.Provider value={position}>
            <ScaleContext.Provider value={scale}>
                <PiecesContext.Provider value={combinedPieces}>
                    <BoardPositionContext.Provider value={boardPosition}>
                        <CustomDragLayer/>
                        <Dialog open={showDeleteMapDialog}
                                maxWidth="xs"
                                onClose={handleDeleteMapAbort}
                                fullWidth>
                            <DialogTitle>Delete map?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete the current map? This cannot be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDeleteMapConfirm}>Delete</Button>
                                <Button onClick={handleDeleteMapAbort}>Keep</Button>
                            </DialogActions>
                        </Dialog>
                        <div className={classes.right}>
                            <div className={classes.top}>
                                <div className={classes.topButtonLeft}/>
                                <TextField inputProps={nameTextFieldInputProps}
                                           autoFocus={!name}
                                           disabled={sending}
                                           placeholder="Choose a name"
                                           value={name}
                                           onChange={handleNameChange}/>
                                <div className={classes.topButtonRight}>
                                    <IconButton size="medium" tooltip="Calculate" Icon={Calculator} onClick={handleCalculate}/>
                                    <IconButton disabled={!name || sending} size="medium" tooltip="Save map" Icon={ContentSave} onClick={handleSaveClick}/>
                                    <IconMenuButton onSelect={handleSelectMap} disabled={maps.length === 0 || sending} Icon={FolderOpen} items={mapMenuItems} size="medium" tooltip="Load map"/>
                                    <IconButton disabled={mapId === 0 || sending} size="medium" tooltip="New map" Icon={PlusCircle} onClick={handleCreateNewMap}/>
                                    <IconButton disabled={mapId === 0 || sending} size="medium" tooltip="Duplicate map" Icon={ContentDuplicate} onClick={handleCopyMap}/>
                                    <IconButton disabled={mapId === 0 || sending} size="medium" tooltip="Delete map" Icon={DeleteForever} onClick={handleDeleteMap}/>
                                </div>
                            </div>
                            <div className={classes.top2} onKeyDown={handleKeyDown} tabIndex={0} onWheel={handleWheel}>
                                <div ref={boardRef} className={classes.top3}>
                                    <Board tiles={tiles}
                                           className={classes.board}
                                           onDrop={handleDrop}
                                           onTileClick={handleTileClick}
                                           onBackgroundClick={handleUnselectTiles}>
                                        {tiles.map(tile => <Tile key={tile.id} tile={tile} onClick={handleTileClick}/>)}
                                        <Grid show={visibleGrid} />
                                        <Toolbar className={classes.bottomRight} show={true}>
                                            <ToolbarButton onClick={handleZoomIn} Icon={MagnifyPlus} tooltip="Zoom in (Shift +)"/>
                                            <ToolbarButton tooltip="Zoom out (Shift -)" Icon={MagnifyMinus} onClick={handleZoomOut}/>
                                        </Toolbar>
                                        <Toolbar className={classes.bottomLeft} show={tileIsSelected}>
                                                <ToolbarButton disabled={sending} tooltip="Rotate tile left (Q)" Icon={RotateLeft} onClick={handleRotateLeft}/>
                                                <ToolbarButton disabled={sending} tooltip="Rotate tile right (E)" Icon={RotateRight} onClick={handleRotateRight}/>
                                                <ToolbarButton disabled={sending} tooltip="Move tile to top" Icon={ArrowCollapseUp} onClick={handleMoveToTop}/>
                                                <ToolbarButton disabled={sending} tooltip="Move tile up (+)" Icon={ArrowUp} onClick={handleMoveOut}/>
                                                <ToolbarButton disabled={sending} tooltip="Move tile down (-)" Icon={ArrowDown} onClick={handleMoveIn}/>
                                                <ToolbarButton disabled={sending} tooltip="Move tile to bottom" Icon={ArrowCollapseDown} onClick={handleMoveToBottom}/>
                                                <ToolbarButton disabled={sending} tooltip="Duplicate tile (Shift + D)" Icon={ContentDuplicate} onClick={handleDuplicate}/>
                                                <ToolbarButton disabled={sending} tooltip="Delete tile (Del)" Icon={DeleteForever} onClick={handleDelete}/>
                                        </Toolbar>
                                        <Toolbar className={classes.topLeft} show={true}>
                                            <ToolbarToggleButton onChange={handleShowGridChange} selected={visibleGrid} Icon={GridIcon} tooltip="Show grid"/>
                                            <ToolbarToggleButton onChange={handleShouldSnapChange} selected={shouldSnap} Icon={Magnet} tooltip="Snap to grid"/>
                                        </Toolbar>
                                    </Board>
                                    <PositionSlider className={classes.positionSliderY}
                                                    axis="y"
                                                    value={positionSliderY}
                                                    onChange={handlePositionSliderYChange}
                                                    onRelease={handlePositionSliderYRelease}/>
                                </div>
                                <PositionSlider className={classes.positionSliderX}
                                                axis="x"
                                                value={positionSliderX}
                                                onChange={handlePositionSliderXChange}
                                                onRelease={handlePositionSliderXRelease}/>
                            </div>
                        </div>
                        <div onKeyDown={handleKeyDown} tabIndex={0}>
                            <Palette/>
                        </div>
                    </BoardPositionContext.Provider>
                </PiecesContext.Provider>
            </ScaleContext.Provider>
        </PositionContext.Provider>
    )
}

export default Builder;
