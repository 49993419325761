import { Redirect, Route, Switch } from "react-router-dom";
import React, { useCallback, useRef, useState } from "react";
import Login from "./page/Login";
import Builder from "./page/Builder";
import { isLoggedIn, removeJwt } from "./storage";
import ErrorContext from "./context/ErrorContext";
import UnauthorizeContext from "./context/UnauthorizeContext";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContext from "./context/SnackbarContext";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles({
    snackbarPaper: {
        padding: "10px 16px",
        backgroundColor: grey[900],
        color: "white"
    }
})

function Layout({ history }) {
    const [ snackbarContent, setSnackbarContent ] = useState();
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);

    const closeRef = useRef();
    const snackbar = useCallback(content => {
        setSnackbarContent(content);
        setSnackbarOpen(true);
        clearTimeout(closeRef.current);
        closeRef.current = setTimeout(() => setSnackbarOpen(false), 2000);
    }, []);

    const [ error, setError ] = useState(false);

    const handleUnauthorize = useCallback(() => {
        removeJwt();
        history.push("/login");
        snackbar("Signed out");
    }, [ history, snackbar ]);

    const handleReload = useCallback(() => {
        setError(false);
        window.location.reload(true);
    }, []);

    const classes = useStyles();

    return (
        <>
            <Dialog open={error}
                    maxWidth="xs"
                    fullWidth>
                <DialogTitle>Sorry...</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Something went wrong, please try again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReload}>Reload</Button>
                </DialogActions>
            </Dialog>
            <SnackbarContext.Provider value={snackbar}>
                <ErrorContext.Provider value={setError}>
                    <UnauthorizeContext.Provider value={handleUnauthorize}>
                            {isLoggedIn() ?
                                <Switch>
                                    <Route exact path="/builder/:mapId" render={routeProps => <Builder {...routeProps}/>}/>
                                    <Redirect to="/builder/0"/>
                                </Switch> :
                                <Switch>
                                    <Route exact path="/login" render={routeProps => <Login {...routeProps}/>}/>
                                    <Redirect to="/login"/>
                                </Switch>
                            }
                    </UnauthorizeContext.Provider>
                </ErrorContext.Provider>
            </SnackbarContext.Provider>
            <Snackbar open={snackbarOpen}><Paper className={classes.snackbarPaper}>{snackbarContent}</Paper></Snackbar>
        </>
    )
}

export default Layout;
