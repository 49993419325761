import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";

import usePieces from "./hook/usePieces";
import Category from "./Category";
import GoogleIcon from "./GoogleIcon";
import useUnauthorize from "./hook/useUnauthorize";

const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        left: 0,
        top: 0,
        height: "calc(100%)",
        width: 400,
        display: "flex",
        flexDirection: "column",
        zIndex: theme.dnd.zIndex.palette
    },
    categories: {
        flex: 1,
        overflowY: "scroll",
    },
    bottom: {
        height: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.background.default,
        borderTop: `1px solid ${grey[300]}`
    },
    pool: {
        margin: 5
    }
}));

function Palette() {
    const classes = useStyles();
    const pieces = usePieces();
    const categories = useMemo(() => {
        return [
            {
                id: 1,
                title: "Floor",
                pieces: Object.values(pieces)
            }
        ]
    }, [ pieces ]);
    const unauthorize = useUnauthorize();
    return (
        <Paper square className={classes.root}>
            <div className={classes.categories}>
                {categories.map(category => <Category key={category.id} category={category}/>)}
            </div>
            <div className={classes.bottom}>
                <Button startIcon={<GoogleIcon/>} onClick={unauthorize}>Sign out</Button>
            </div>
        </Paper>
    )
}

export default React.memo(Palette);
