import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Constants from "./constant/constants";
import useScale from "./hook/useScale";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: "100%",
        zIndex: theme.dnd.zIndex.grid,
        pointerEvents: "none",
        stroke: "rgba(0, 0, 0, 0.35)",
        strokeWidth: 2,
        position: "absolute",
        left: 0,
        top: 0
    }
}));

function Grid({ show }) {
    const classes = useStyles();
    const scale = useScale();

    if (!show) {
        return null;
    }

    const paths = [];
    for (let x = 0; x <= window.screen.width; x += Constants.TILE_SIZE * scale) {
        paths.push(
            <path key={"x_" + x} d={`m ${x} 0 v ${window.screen.height}`}/>
        )
    }
    for (let y = 0; y <= window.screen.height; y += Constants.TILE_SIZE * scale) {
        paths.push(
            <path key={"y_" + y} d={`m 0 ${y} h ${window.screen.width}`}/>
        )
    }

    return (
        <svg className={classes.root}>
            {paths}
        </svg>
    )
}

export default React.memo(Grid);
