import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import IconButton from "./IconButton";
import Constants from "./constant/constants";

const useStyles = makeStyles({
    root: {
        border: `1px solid rgba(0, 0, 0, 0.35)`
    },
    button: {
        width: Constants.TILE_SIZE - 2,
        height: Constants.TILE_SIZE - 2,
    },
})

function ToolbarButton({ onClick, Icon, tooltip, disabled }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <IconButton className={classes.button} disabled={disabled} tooltip={tooltip} Icon={Icon} onClick={onClick}/>
        </div>
    )
}

export default React.memo(ToolbarButton);
