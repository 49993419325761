import Constants from "./constant/constants";

export function snapX({ x, shape, rotation }) {
    const { width, height } = shape;
    const offset = Math.abs(Math.cos(rotation/180*Math.PI)) * width / 2 + Math.abs(Math.sin(rotation/180*Math.PI)) * height / 2;
    return Math.round(Math.floor(2 * (x - offset) / Constants.TILE_SIZE + 0.5) * Constants.TILE_SIZE / 2 + offset);
}

export function snapY({ y, shape, rotation }) {
    const { width, height } = shape;
    const offset = Math.abs(Math.sin(rotation/180*Math.PI)) * width / 2 + Math.abs(Math.cos(rotation/180*Math.PI)) * height / 2;
    return Math.round(Math.floor(2 * (y - offset) / Constants.TILE_SIZE + 0.5) * Constants.TILE_SIZE / 2 + offset);
}

export function wrapAround({ value, min, max }) {
    const diff = max - min;
    return value - (Math.floor((value - min)/diff)) * diff;
}

export function calcPoolX({ sourceClientOffset, scale, shape, position }) {
    return sourceClientOffset.x / scale + shape.width / 2 / scale * Constants.POOL_SCALE + position.x;
}

export function calcPoolY({ sourceClientOffset, scale, shape, position }) {
    return sourceClientOffset.y / scale + shape.height / 2 / scale * Constants.POOL_SCALE + position.y;
}

export function calcTileX({ initialX, differenceFromInitialOffset, scale }) {
    return initialX + differenceFromInitialOffset.x / scale;
}

export function calcTileY({ initialY, differenceFromInitialOffset, scale }) {
    return initialY + differenceFromInitialOffset.y / scale;
}

export function parseShapeD(d) {
    return parseTileSizeDependentString(d);
}

export function parseShapeWidth(width) {
    return parseInt(parseTileSizeDependentString(width));
}

export function parseShapeHeight(height) {
    return parseInt(parseTileSizeDependentString(height));
}

function parseTileSizeDependentString(s) {
    // eslint-disable-next-line no-new-func
    return new Function("TILE_SIZE", `return \`${s}\`;`)(Constants.TILE_SIZE);
}

export function calcPatternWrapperStyle({ x, y, position, shape, scale, rotation }) {
    return {
        position: "absolute",
        left: (x - shape.width / 2 - position.x) * scale,
        top: (y - shape.height / 2 - position.y) * scale,
        width: shape.width * scale,
        height: shape.height * scale,
        transform: `rotate(${rotation}deg)`
    }
}

export function calculate({ tiles }) {
    const res = tiles
        .map(tile => tile.piece)
        .reduce((arr, piece) => {
            const element = arr.find(e => e.piece.id === piece.id);
            if (element) {
                element.nbr++;
                return arr;
            } else {
                return [ ...arr, { piece, nbr: 1 } ];
            }
        }, [])
        .sort((e1, e2) => e1.piece.name > e2.piece.name ? 1 : -1);
    res.forEach(e => {
        e.diff = e.piece.count - e.nbr;
    });
    return res;
}
