import React  from "react";
import { IconButton as MuiIconButton, Tooltip } from "@material-ui/core";

function wrapInTooltip({ content, tooltip }) {
    return (
        <Tooltip title={tooltip}>
            {content}
        </Tooltip>
    )
}

function IconButton({ Icon, onClick, tooltip, size = "small", className, disabled }) {
    const content = (
        <MuiIconButton disabled={disabled} size={size} onClick={onClick} className={className}>
            <Icon/>
        </MuiIconButton>
    );
    return disabled ? content : wrapInTooltip({ content, tooltip });
}

export default React.memo(IconButton);
