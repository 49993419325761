import React, { useCallback, useMemo, useState } from "react";
import Menu from "@material-ui/core/Menu";

import IconButton from "./IconButton";
import MenuItem from "./MenuItem";

const anchorOrigin = {
    horizontal: "center",
    vertical: "bottom"
}

function IconMenuButton({ Icon, onSelect, tooltip, size = "small", disabled, items }) {
    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleButtonClick = useCallback(e => {
        setAnchorEl(e.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);
    
    const handleItemClick = useCallback(value => {
        setAnchorEl(null);
        onSelect(value);
    }, [onSelect]);
    
    const itemElements = useMemo(() => {
        return items.map(({ label, value }, i) => {
            return (
                <MenuItem key={i} onClick={handleItemClick} label={label} value={value}/>
            );
        })
    }, [handleItemClick, items]);

    return (
        <>
            <IconButton disabled={disabled} size={size} tooltip={tooltip} Icon={Icon} onClick={handleButtonClick}/>
            <Menu anchorEl={anchorEl}
                  getContentAnchorEl={undefined}
                  anchorOrigin={anchorOrigin}
                  open={!!anchorEl}
                  onClose={handleClose}>
                {itemElements}
            </Menu>
        </>
    )
}

export default React.memo(IconMenuButton);
