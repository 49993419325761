import React, { useCallback } from "react";
import { Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 0,
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
        "&$selected": {
            backgroundColor: theme.palette.background.paper
        }
    },
    selected: {}
}));

function ToggleIconButton({ Icon, onChange, tooltip, selected, className }) {
    const handleChange = useCallback(() => {
        onChange(!selected);
    }, [ onChange, selected ]);

    const classes = useStyles();

    return (
        <Tooltip title={tooltip}>
            <ToggleButton classes={{ root: clsx([ classes.root, className ]), selected: classes.selected }}
                          onChange={handleChange}
                          selected={selected}
                          value="value">
                <Icon/>
            </ToggleButton>
        </Tooltip>
    )
}

export default React.memo(ToggleIconButton);
