const TILE_SIZE = 32;

const Constants = {
    TILE_SIZE,
    VISIBLE_BOARD_WIDTH: TILE_SIZE * 30,
    VISIBLE_BOARD_HEIGHT: TILE_SIZE * 20,
    ITEM_TYPE: "type",
    POOL_SCALE: 0.5,
    INITIAL_SCALE: 1
};

export default Constants;
