import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { DndProvider } from "react-dnd"
import Backend from "react-dnd-html5-backend"
import { BrowserRouter, Route } from "react-router-dom";

import theme from "./theme";
import Layout from "./Layout";

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <DndProvider backend={Backend}>
                <BrowserRouter>
                    <Route component={Layout}/>
                </BrowserRouter>
            </DndProvider>
        </MuiThemeProvider>
    );
}

export default App;
